import { FC } from "react";
import { Image, useBreakpointValue } from "@chakra-ui/react";
import {
  getAuth,
  signInWithRedirect,
  inMemoryPersistence,
} from "@firebase/auth";
import { getSupportedProvider, SupportedProvider } from "src/lib/firebase";
import React from "react";
import Router from "next/router";
import { Button } from "src/components/atoms/Button";
import { authErrorVar } from "src/providers/UserProvider";
import * as Sentry from "@sentry/nextjs";
import { toError } from "src/utils/toError";
import { ErrorWithCode } from "src/utils/ErrorWithCode";
import { isAndroid } from "src/utils/isMobile";

const signInWith = async (provider: SupportedProvider) => {
  try {
    if (provider === "facebook" && isAndroid()) {
      throw new ErrorWithCode("sign-in-with/android-facebook-login-deprecated");
    }
    const auth = getAuth();
    await auth.setPersistence(inMemoryPersistence);
    await signInWithRedirect(auth, getSupportedProvider(provider));
    await Router.push("/");
  } catch (error) {
    Sentry.captureException(error);
    authErrorVar(toError(error));
  }
};

type SocialMediaLoginButtonProps = {
  provider: "google" | "facebook" | "apple";
};

export const SocialMediaLoginButton: FC<SocialMediaLoginButtonProps> = ({
  provider,
}) => {
  const src = `/logos/${provider}-logo.svg`;
  const btnWidth = useBreakpointValue({ base: 12, sm: 16 });
  const imgWidth = useBreakpointValue({ base: "30px", sm: "40px" });

  return (
    <Button
      p="2"
      bg="white"
      w={btnWidth}
      h={btnWidth}
      rounded="full"
      onClick={() => {
        void signInWith(provider);
      }}
    >
      <Image src={src} height={imgWidth} width={imgWidth} alt={provider} />
    </Button>
  );
};
